import { Autocomplete, AutocompleteProps, Box, IconButton, Paper, Theme } from '@mui/material';
import { styled } from '@mui/material/styles';
import TextField from '@mui/material/TextField';

import { shouldForwardProps } from '../../utils/mui-styled.utils';
import { AutocompleteOption } from './input-autocomplete.component';

export const StyledAdornmentContainer = styled(Box)({
	position: 'relative',
});
export const highlighted = {
	fontWeight: 'normal',
};

export const StyledSearchIconAdornment = styled(Box)({
	position: 'absolute',
	top: 0,
	left: 12,
	bottom: 0,
	display: 'flex',
	alignItems: 'center',
	zIndex: 1,
});

export const StyledClearButton = styled(IconButton)({
	'&:hover': {
		cursor: 'pointer',
		backgroundColor: 'transparent',
	},
	'&:focus-visible': {
		outlineOffset: -1,
	},
});

export const StyledSendButton = styled(IconButton)(
	({
		theme: {
			palette: { sizes, borderRadius, colorScheme },
		},
	}) => ({
		position: 'absolute',
		right: sizes.sizeHalf,
		top: sizes.sizeHalf,
		borderRadius: borderRadius.commonRadius - 4 > 0 ? borderRadius.commonRadius - 4 : 0,
		...colorScheme.customStyle.sendButton,
	}),
);

export const StyledOptionBox = styled('li')(
	({
		theme: {
			palette: { sizes, colorScheme },
		},
	}) => ({
		width: '100%',
		fontSize: '1.075rem',
		minHeight: `${sizes.size_6}px !important`,
		fontWeight: 'bold',
		margin: 0,
		cursor: 'pointer',

		'&.Mui-focused': {
			'&:hover': {
				backgroundColor: colorScheme.colorVariables.colorPrimarySoft,
			},
		},
		'&[aria-selected="true"]': {
			backgroundColor: colorScheme.colorVariables.colorLightShadow,
		},
	}),
);

interface StyledPaperProps {
	hasInputValue: boolean;
	options: AutocompleteOption[];
}
export const StyledPaper = styled(Paper, {
	shouldForwardProp: shouldForwardProps(['hasInputValue', 'options']),
})<StyledPaperProps>(
	({
		theme: {
			palette: { sizes, colorScheme, boxShadow, borderRadius },
		},
		hasInputValue,
		options,
	}) => ({
		display: hasInputValue && options.length ? 'block' : 'none',
		marginBottom: sizes.sizeHalf,
		color: colorScheme.colorVariables.colorText,
		boxShadow: boxShadow.angle.large,
		borderRadius: borderRadius.commonRadius,
	}),
);

export const StyledListbox = styled('ul')(
	({
		theme: {
			palette: { borderRadius, sizes },
		},
	}) => ({
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'flex-start',
		borderRadius: borderRadius.commonRadius,
		maxHeight: 278,
		padding: '0 !important',
		margin: `${sizes.sizeQuarter}px !important`,
		fontWeight: 'bold',
	}),
);

interface StyledAutocompleteProps<
	T,
	Multiple extends boolean | undefined,
	DisableClearable extends boolean | undefined,
	FreeSolo extends boolean | undefined,
> extends AutocompleteProps<T, Multiple, DisableClearable, FreeSolo> {
	isFocused?: boolean;
}
export const StyledAutocomplete = styled(Autocomplete)<StyledAutocompleteProps<any, false, false, false>>(
	({
		theme: {
			palette: { borderRadius, colorScheme },
		},
		isFocused,
	}) => ({
		position: 'relative',
		height: '100%',
		width: '100%',
		borderRadius: borderRadius.commonRadius,

		transition: 'all 0.8s',
		'&.Mui-focused': {
			transition: () => (isFocused ? 'all 0.1s' : 'none'),
			boxShadow: `0 0 0 2px ${colorScheme.colorVariables.colorPrimary}`,
		},
	}),
);

export const StyledTextField = styled(TextField)(
	({
		theme: {
			palette: { borderRadius, boxShadow },
		},
	}) => ({
		transition: 'all 0.8s',
		marginBottom: 0,
		paddingRight: '0px',
		boxShadow: boxShadow.angle.xsmall,
		background: 'white',
		borderRadius: borderRadius.commonRadius,
	}),
);

export const InputSx = ({
	theme,
	isFocused,
	isLiveChatShown,
	isSendFreeTextDisabled,
}: {
	theme: Theme;
	isFocused: boolean;
	isLiveChatShown: boolean;
	isSendFreeTextDisabled: boolean;
}) => ({
	paddingRight: '0px !important',
	border: `1px solid ${theme.palette.colorScheme.colorVariables.colorBorder}`,
	'&:first-of-type': {
		transition: 'padding 0s',
		padding: `4px 16px 6px ${isSendFreeTextDisabled ? '40px' : '16px'}`,
		minHeight: 48,
	},
	'& textarea:first-of-type': {
		overflowY: isFocused ? 'auto' : 'hidden',
		transition: 'all 0.2s',
		paddingRight: `${isSendFreeTextDisabled ? 0 : 36}px !important`,
		borderTopRightRadius: theme.palette.borderRadius.commonRadius,
		borderBottomRightRadius: theme.palette.borderRadius.commonRadius,
	},
	fontFamily: isLiveChatShown
		? theme.palette.colorScheme.fontFamilyRegular
		: theme.typography.smallViewPort.typeInput1.fontFamily,
	'&.Mui-disabled': {
		color: theme.palette.colorScheme.colorVariables.colorNote,
		backgroundColor: theme.palette.colorScheme.colorVariables.colorInactive,
		cursor: 'auto',
		WebkitTextFillColor: theme.palette.colorScheme.colorVariables.colorNote,
	},
});
