import { LinearProgress } from '@mui/material';
import { styled } from '@mui/material';

import { shouldForwardProps } from '../../utils/mui-styled.utils';
import { Button } from '../button/button.component';
import { Chip } from '../chip/chip.component';
import { Info } from '../info/info.component';

export const UploaderRoot = styled('div')({
	width: '100%',
});

export const StyledInput = styled('input')({
	display: 'none',
});


export const ActionButton = styled(Chip, {
	shouldForwardProp: shouldForwardProps(['isDisabled', 'hasPreviewImages', 'isProgressing']),
})<{ isDisabled?: boolean; hasPreviewImages?: boolean; isProgressing?: boolean }>(
	({
		theme: {
			palette: {
				sizes,
				colorScheme: { colorVariables },
			},
		},
		isDisabled,
		hasPreviewImages,
		isProgressing,
	}) => ({
		width: '100%',
		color: colorVariables.colorPrimary,
		marginBottom: sizes.size_1,
		'& svg': {
			color: isDisabled ? 'currentColor' : colorVariables.colorPrimary,
		},
		...(hasPreviewImages &&
			!isProgressing && {
				backgroundColor: `${colorVariables.colorSysBgAlert} `,
				color: `${colorVariables.colorSysTextAlert} !important`,
				'&:hover, &:focus-visible, &:focus, &:focus:not(:focus-visible)': {
					backgroundColor: `${colorVariables.colorSysBgAlert} !important`,
				},
			}),
	}),
);


export const CameraButton = styled(ActionButton, {
	shouldForwardProp: shouldForwardProps(['isDisabled', 'hasPreviewImages', 'isProgressing']),
})<{
	isDisabled?: boolean;
	hasPreviewImages?: boolean;
	isProgressing?: boolean;
}>(
	({
		theme: {
			palette: {
				colorScheme: { colorVariables },
			},
		},
		isDisabled,
		hasPreviewImages,
		isProgressing,
	}) => ({
		marginBottom: 0,
		'& svg': {
			color: isDisabled ? 'currentColor' : colorVariables.colorPrimary,
		},
		...(hasPreviewImages &&
			!isProgressing && {
				//TODO:REMOVE IMPORTANT AFTER UPDATE CHIP TO STYLED
				backgroundColor: `${colorVariables.colorSysBgAlert} !important`,
				color: colorVariables.colorSysTextAlert,
				'&:hover, &:focus-visible, &:focus, &:focus:not(:focus-visible)': {
					backgroundColor: `${colorVariables.colorSysBgAlert} !important`,
				},
			}),
	}),
);

export const ContinueButton = styled(Button)(
	({
		theme: {
			palette: { sizes },
		},
	}) => ({
		marginTop: sizes.size_2,
	}),
);

export const PreviewWrapper = styled('div')(
	({
		theme: {
			palette: {
				colorScheme: { colorVariables },
				borderRadius,
				boxShadow,
				sizes,
			},
		},
	}) => ({
		position: 'relative',
		width: '100%',
		backgroundColor: colorVariables.colorInactive,
		boxShadow: boxShadow.angle.xsmall,
		borderRadius: borderRadius.card,
		overflow: 'hidden',
		marginBottom: sizes.size_2,
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
	}),
);


export const ImagePreviewStyled = styled('img', {
	shouldForwardProp: shouldForwardProps(['progress']),
})<{ progress?: boolean }>(({ progress }) => ({
	height: 257,
	filter: progress ? 'blur(4px)' : 'none',
}));

export const Blurred = styled('div')({
	filter: 'blur(4px)',
});

export const BlurredLayer = styled('div')({
	position: 'absolute',
	top: 0,
	bottom: 0,
	left: 0,
	right: 0,
	backgroundColor: 'white',
	opacity: 0.7,
	zIndex: 1,
});

export const RemoveUploadedImageButton = styled(Button)(
	({
		theme: {
			palette: { sizes },
		},
	}) => ({
		position: 'absolute',
		right: sizes.size_2,
		top: sizes.size_2,
		borderRadius: '50%',
		padding: sizes.sizeHalf,
		'& svg': { fill: 'white' },
	}),
);

export const Error = styled(Info)(
	({
		theme: {
			palette: { sizes },
		},
	}) => ({
		margin: `${sizes.size_2}px 0`,
	}),
);

export const Progress = styled('div')(
	({
		theme: {
			palette: { sizes },
		},
	}) => ({
		position: 'absolute',
		bottom: '50%',
		left: sizes.size_2,
		right: sizes.size_2,
		textAlign: 'center',
		transform: 'translateY(50%)',
		zIndex: 1,
	}),
);

export const ProgressLabelsContainer = styled('div')({
	display: 'flex',
	justifyContent: 'space-between',
});

export const ProgressLabel = styled('span')(
	({
		theme: {
			typography: { smallViewPort },
		},
	}) => ({
		...smallViewPort.typeSysText1,
		display: 'block',
	}),
);

export const LinearProgressStyled = styled(LinearProgress)(
	({
		theme: {
			palette: { sizes },
		},
	}) => ({
		borderRadius: sizes.sizeHalf,
	}),
);

export const VideoPreview = styled('div')({
	position: 'relative',
});

export const Video = styled('video')(
	({
		theme: {
			palette: { borderRadius },
		},
	}) => ({
		width: '100%',
		borderRadius: borderRadius.card,
	}),
);

export const NoPreviewRoot = styled('div')(
	({
		theme: {
			palette: { borderRadius, sizes },
			typography: { smallViewPort },
		},
	}) => ({
		borderRadius: borderRadius.card,
		textAlign: 'center',
		padding: `${sizes.size_3}px ${sizes.size_4}px ${sizes.size_4}px`,
		margin: `${sizes.size_3}px ${sizes.size_1}%`,
		display: 'grid',
		...smallViewPort.typeHeading1,
		'& > svg': {
			margin: `${sizes.size_1}px auto`,
		},
		'& > p': {
			...smallViewPort.typeHeading1,
		},
	}),
);

export const TakePictureButton = styled(Button)(
	({
		theme: {
			palette: { sizes },
		},
	}) => ({
		marginTop: sizes.size_2,
		marginBottom: sizes.size_1,
	}),
);
